<template>
  <footer>
    <div class="container">
      <div class="section_footer">
        <div class="img_logo">
          <img src="./../../public/imgs/logo.png" alt="logo" />
        </div>
        <div class="links">
          <div class="col">
            <a href="/" class="link">
              <p class="titulo_footer">{{ $t('message.inicio') }}</p>
            </a>
            <a class="link" href="/#servico">
              <p class="titulo_footer">{{ $t('message.servico') }}</p>
            </a>
            <a class="link" href="/#sobre">
              <p class="titulo_footer">{{ $t('message.sobre') }} </p>
            </a>
          </div>
          <div class="icones_footer">
            <div>
              <img src="./../../public/imgs/Facebook_black.png" alt="logo" />
            </div>
            <div>
              <img src="./../../public/imgs/Instagram_black.png" alt="logo" />
            </div>
          </div>
        </div>
        <div class="contato_footer">
          <p class="titulo_2_footer">{{ $t('message.fale_com_a_gente') }}</p>
          <div class="icone_txt_footer">
            <div>
              <img src="./../../public/imgs/local_phone.png" alt="logo" />
            </div>
            <div>
              <p class="ml-4 titulo_footer">+ 55 (11) 97659-4994</p>
            </div>
          </div>
          <div class="icone_txt_footer">
            <div>
              <img src="./../../public/imgs/whatsapp.png" alt="logo" />
            </div>
            <div>
              <p class="ml-4 titulo_footer">+ 55 (11) 97659-4994</p>
            </div>
          </div>
          <div class="icone_txt_footer">
            <div>
              <img src="./../../public/imgs/email.png" alt="logo" />
            </div>
            <div>
              <p class="ml-4 titulo_footer">comercial@bgamification.com</p>
            </div>
          </div>
          <div class="icone_txt_footer">
            <div>
              <img src="./../../public/imgs/email.png" alt="logo" />
            </div>
            <div>
              <p class="ml-4 titulo_footer">partners@bgamification.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg_footer">
      <div class="container">
        <div class="section_bottom">
          <div>
            Copyright © BGamification Company 2023 - Todos os Direitos Reservados
          </div>
          <div class="section_bottom">
            {{ $t('message.desenvolvido') }}
            <div class="ml-3">
              <a href="https://hoomweb.com/" target="_blank" class="link">
                <img src="./../../public/imgs/logo_hoomweb.png" alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'FooterComponent'
}
</script>

<style scoped>
.img_logo {
  width: 15rem;
}
.img_logo img{
  width: 100%;
}

.titulo_footer {
  color: var(--White, #FFF);
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.titulo_2_footer {
  color: var(--White, #FFF);
  font-family: Roboto;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;

}

.section_bottom,
.section_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icones_footer {
  display: flex;
  justify-content: space-between;
}

.icone_txt_footer {
  display: flex;
  justify-content: flex-start;

}

.bg_footer {
  margin-top: 2rem;
  display: flex;
  padding: 16px 137px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--Primary, #6E43EA);
}

@media (max-width: 800px) {

  .section_footer {
    flex-direction: column-reverse;
    align-items: center;
  }

  .img_logo {
    width: auto;
    margin: 2rem 0;
  }

  .section_bottom {
    flex-direction: column;
    align-items: center;
  }

  .section_bottom div {
    width: 90vw;
    text-align: center;
  }
}
</style>
