<template>
  <div class="container" id="cliente">
    <div class="titulo">
      <h1>{{ $t('message.clientes') }}</h1>
      <img class="img_line" src="./../../public/imgs/line.png" />
    </div>

    <div class="row">
      <!-- <div class="col center">
        <img src="./../../public/imgs/iniative.png" class="img_logo_client" />
      </div> -->
      <!-- <div class="col center">
        <img src="./../../public/imgs/igoal.png" class="img_logo_client" />
      </div> -->
      <!-- <div class="col center">
        <img src="./../../public/imgs/amazon.png" class="img_logo_client" />
      </div> -->
      <!-- <div class="col center">
        <img src="./../../public/imgs/real.png" class="img_logo_client" />
      </div> -->
      <!-- <div class="col center">
        <img src="./../../public/imgs/for.png" class="img_logo_client" />
      </div> -->
      <!-- <div class="col center">
        <img src="./../../public/imgs/opus.png" class="img_logo_client" />
      </div> -->
      <!-- <div class="col center">
        <img src="./../../public/imgs/iberogram.png" class="img_logo_client" />
      </div> -->
    </div>
    <div class="row">
      <div class="col center">
        <img src="./../../public/imgs/muv.png" class="img_logo_client" />
      </div>
      <div class="col center">
        <img src="./../../public/imgs/top.png" class="img_logo_client" />
      </div>
      <div class="col center">
        <img src="./../../public/imgs/fort.png" class="img_logo_client" />
      </div>
    </div>

    <div class="row">
      <div class="col center">
        <img src="./../../public/imgs/paradiselogoescrito1.png" class="img_logo_client" />
      </div>
      <div class="col center">
        <img src="./../../public/imgs/gb.png" class="img_logo_client" />
      </div>
      <div class="col center">
        <img src="./../../public/imgs/myseat.svg" class="img_logo_client" />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col center">
        <img src="./../../public/imgs/logo_cliente_5.png" class="img_logo_client" />
      </div>
      <div class="col center">
        <img src="./../../public/imgs/logo_cliente_3.png" class="img_logo_client" />
      </div>
      <div class="col center">
        <img src="./../../public/imgs/logo_cliente_4.png" class="img_logo_client" />
      </div>
      <div class="col center">
        <img src="./../../public/imgs/logo_cliente_6.png" class="img_logo_client" />
      </div>
    </div> -->
  </div>
</template>

<script>

export default {
  name: 'ClientesComponent'
}
</script>

<style>
.img_logo_client {
  max-width: 11rem;
}

@media (max-width: 600px) {
  .img_line {
    width: 80vw;
  }
}
</style>
