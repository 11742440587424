// src/i18n/index.js
import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    message: {
      inicio: 'Start',
      servico: 'Services',
      sobre: 'About Us',
      contato: 'Contact',
      nossos_servicos: 'Our services',
      clientes: 'Partners',
      fechar: 'To close',
      exemplo: 'Example: ',

      midia_performace: 'Performance Media',
      midia_performace_paragrafo: 'Through performance media (CPI, CPC CPA) we are able to have the best inventory options to deliver your CAC according to the objectives: Traffic, installation, user acquisition ...',
      midia_performace_paragrafo_full: 'Through performance media (CPI, CPC CPA) we are able to have the best inventory options to deliver your CAC according to the objectives: Traffic, installation, user acquisition, FTB, purchase/subscriptions. + 30 partners (networks, vehicles , publishers), global activation.',

      midia_programatica: 'Programmatic media',
      midia_programatica_paragrafo: 'We have an inventory of Apps, Games, websites and much more to connect your brand, product or service with audiences through Open Auction, Preferred Deal or guarantee of ...',
      midia_programatica_paragrafo_full: 'We have an inventory of Apps, Games, websites and much more to be able to connect your brand, product or service with audiences through Open Auction, Preferred Deal or guarantee according to your budget and the competitive CPM in the market and have a better ROI for each campaign. Types of formats: Display, video, audio, Native, OOH, social networks.',

      game_advertising: 'Ads In-Game',
      game_advertising_paragrafo: 'Through the application of advertising on premium Mobile Games or HTML inventories, brands are able to connect with a broad and highly engaged audience, ...',
      game_advertising_paragrafo_full: 'Through the application of advertising on premium Mobile Games or HTML inventories, brands are able to connect with a broad and highly engaged audience, according to demographic customization.\nThe popularization of online games and increased connectivity have allowed the introduction of advertisements dynamic, which can be replaced in real time and vary depending on geographic location, for example.\nFocus on awareness campaigns and/or that require traffic. For each objective, there is a winning format such as Rewarded Videos (videos that bring compensation to the user, banners, Interstitial and others. Each one that fits the brand`s budget and attractive CPM.',

      desenvolvimento_jogos: 'Custom game development',
      desenvolvimento_jogos_paragrafo: 'We have a network of partners and studios that create projects for customized games in different formats and platforms such as Apps, HTML, webApps, PC games, etc. ...',
      desenvolvimento_jogos_paragrafo_full: 'We have a network of partners and studios that create projects for customized games in different formats and platforms such as Apps, HTML, webApps, PC games, etc.\nIn this Business development pillar we include complete gaming development and curation strategies, as well as administration user acquisition.',

      gamificacao: 'App Gamification',
      gamificacao_paragrafo: 'We use gamification as a powerful tool in existing Apps that have a low level of retention. In addition to gamifying Apps, our business development partners team ...',
      gamificacao_paragrafo_full: 'We use gamification as a powerful tool in existing Apps that have a low level of retention.\nIn addition to gamifying the Apps, our business development partners team carry out In-app activities to build loyalty and engage the user for repurchase, also helping with the performance strategy and improving ROI (return on investment).',

      metaverso: 'Metaverse',
      metaverso_paragrafo: 'The metaverse is a virtual universe where people can interact with each other through personalized avatars. It is considered a kind of immersive 3D Internet ...',
      metaverso_paragrafo_full: 'The metaverse is a virtual universe where people can interact with each other through personalized avatars. It is considered a type of immersive 3D Internet.\nWe are able to create interaction experiences for brands such as: AR (Augmented Reality) games to include in an OOH campaign, packaging, POS, etc. \n Our partner studios are in different regions of the world that deliver quality VR development for all types of projects.',

      influenciador: 'Influencers, Streamers & Content Creators',
      influenciador_paragrafo: 'We have a creative hub structure that organizes your campaign according to the brand`s objectives with differentiated strategies through influencers, Streamers ...',
      influenciador_paragrafo_full: 'We have the structure of a creative hub that organizes your campaign according to the brand`s objectives with differentiated strategies through influencers, Streamers and Content Creators spread across Brazil, Argentina Mexico USA and Europe.\nWe use a methodology to find the best profile and strategy according to its own tool - ISC Matrix.\nAlso a UCG partner to optimize and gain capillarity in your campaigns.',

      tv: 'Digital TV',
      tv_paragrafo: 'We create activations to boost your campaigns through Digital TVs. For Game brands, our Business Development team connects with specialized developers ...',
      tv_paragrafo_full: 'We create activations to boost your campaigns through Digital TVs.\nFor Game brands, our Business Development team connects with developers specialized in taking your gamification to various Digital TV platforms to gain scale.',

      ooh: 'OOH Gamified',
      ooh_paragrafo: 'Through OOH Gamified we were able to leverage the engagement of the impacted audience at a bus stop, elevator, subway, etc., at a higher level ...',
      ooh_paragrafo_full: 'Through OOH Gamified we were able to leverage the engagement of the impacted audience at a bus stop, elevator, subway, etc., at a higher level, raising through interaction an awareness never achieved in OOH. In addition to enabling Loyalty campaigns.',

      esport: 'eSports',
      esport_paragrafo: 'We help connect brands with an engaged community through teams, streamers, organization of tournaments, leagues and broadcasts on different ...',
      esport_paragrafo_full: 'We help connect brands with an engaged community through teams, streamers, organization of tournaments, leagues and broadcasts on different platforms led by influencers/streamers with +1 M followers.\nOur teams guarantee monthly delivery of impressions, this way brands can purchase through CPM.\nWe also organize tournaments on an exclusive platform in which we guarantee between 5,000 and 50,000 amateur or professional players competing and having fun together.\nOur teams are built according to the brands` objectives and fit into all types of budget.',

      licenciamento: 'Licensing',
      licenciamento_paragrafo: 'We help large game brands to license and be able to be close to fans at different moments in their lives and in different gamification segments, such as ...',
      licenciamento_paragrafo_full: 'We help large game brands to license and be close to fans at different moments in their lives and in different gamification segments such as physical board games, puzzles, cards, etc.\nAs well as transforming your game brand into consumer products massive.',

      sobre_paragrafo: 'We are a business hub focused on connecting brands to a variety of audiences, globally, with a customer centricity concept and help build technological solutions to raise awareness and grow performance through entertainment and technology.',

      entre_contato: 'Get in touch for partnerships, questions or suggestions',
      seu_nome: 'Your name',
      input_name: 'José da Silvia',
      input_phone: '(00) 90000-0000',
      phone: 'Telephone',
      escreva_mensagem: 'Write your message here',
      enviar_mensagem: 'Send Message',

      errorValidInputs: 'Fill in the form fields. They are mandatory for your contact to be sent.',
      fale_com_a_gente: 'Talk to us',
      desenvolvido: 'Developed by',

      enviando: 'Sending...',
      email_invalido: 'Invalid email',
      error: 'An error occurred, please try again later!',
      sucesso: 'Your email has been sent successfully!'

    }
  },
  pt: {
    message: {
      inicio: 'Inicío',
      servico: 'Serviços',
      sobre: 'Sobre nós',
      contato: 'Contato',
      nossos_servicos: 'Nossos Serviços',
      clientes: 'Parceiros',
      fechar: 'Fechar',
      exemplo: 'Exemplo: ',

      midia_performace: 'Mídia de Performance',
      midia_performace_paragrafo: 'Através da mídia de performance (CPI, CPC CPA) conseguimos ter as melhores opções de inventário para entregar o seu CAC de acordo com os objetivo: Tráfego, instalaçao, user acquisition,...',
      midia_performace_paragrafo_full: 'Através da mídia de performance (CPI, CPC CPA) conseguimos ter as melhores opções de inventário para entregar o seu CAC de acordo com os objetivo: Tráfego, instalaçao, user acquisition, FTB, compra/assinaturas.+ de 30 parceiros (redes, veículos, publishers), ativação global.',

      midia_programatica: 'Mídia programática',
      midia_programatica_paragrafo: 'Temos um inventário em Apps, Games, websites e muito mais para poder conectar sua marca, produto ou serviço com audiências através de Open Auction, Prefered Deal ou guarante de ...',
      midia_programatica_paragrafo_full: 'Temos um inventário em Apps, Games, websites e muito mais para poder conectar sua marca, produto ou serviço com audiências através de Open Auction, Prefered Deal ou guarantee de acordo com o seu budget e com o CPM competitivo no mercado e ter um ROI melhor a cada campanha.Tipos de formatos: Display, vídeo, áudio, Native, OOH, redes sociais. ',

      game_advertising: 'Ads In-Game',
      game_advertising_paragrafo: 'Através do aplicaçao de publicidade no inventários premium de Games Mobiles ou HTML , as marcas conseguem se conectar com um público amplo e muito engajado, de acordo com a customi. ...',
      game_advertising_paragrafo_full: 'Através do aplicaçao de publicidade no inventários premium de Games Mobiles ou HTML , as marcas conseguem se conectar com um público amplo e muito engajado, de acordo com a customizaçao demográfica.\nA popularização dos jogos online e o aumento da conectividade permitiram a introdução de anúncios dinâmicos, que podem ser substituídos em tempo real e variam conforme a localização geográfica, por exemplo.\nFoco para campanhas de awareness e ou que necessitem de tráfego. Para cada objetivo, há um formato vencedor como Videos Rewarded (vídeos que trazem uma compensação para o usuário, banners, Interstitial e outros. Cada um que encaixe com o budget da marca e CPM atrativo.',

      desenvolvimento_jogos: 'Desenvolvimento de jogos customizados',
      desenvolvimento_jogos_paragrafo: 'Possuímos uma rede de parceiros e estúdios que criam o projeto para jogos customizados em diversos formatos e plataformas como Apps, HTML, webApps, jogos de PCs etc. ...',
      desenvolvimento_jogos_paragrafo_full: 'Possuímos uma rede de parceiros e estúdios que criam o projeto para jogos customizados em diversos formatos e plataformas como Apps, HTML, webApps, jogos de PCs etc.\nNeste pilar de Business development incluímos estratégias completas de desenvolvimento e curadoria do gaming, assim como administração da aquisição de usuários.',

      gamificacao: 'Gamificação de Apps',
      gamificacao_paragrafo: 'Utilizamos a gamificaçao como ferramenta poderosa em Apps existentes e que tem baixo nível de retenção. Além de gamificar os Apps, nosso business development partners team ...',
      gamificacao_paragrafo_full: 'Utilizamos a gamificaçao como ferramenta poderosa em Apps existentes e que tem baixo nível de retenção.\nAlém de gamificar os Apps, nosso business development partners team realizam atividades In-app para fidelizar e engajar o usuário para uma recompra, ajudando também na estratégia de performance e melhorando o ROI (retorno do investimento).',

      metaverso: 'Metaverso',
      metaverso_paragrafo: 'O metaverso é um universo virtual onde as pessoas podem interagir entre si por meio de avatares personalizados. É considerado uma espécie de Internet 3D imersiva. Conseguimos criar para ...',
      metaverso_paragrafo_full: 'O metaverso é um universo virtual onde as pessoas podem interagir entre si por meio de avatares personalizados. É considerado uma espécie de Internet 3D imersiva.\nConseguimos criar para as marcas experiências de interação como: jogos em AR (Realidade aumentada) para incluir em uma campanha em OOH, embalagens, PDV etc. \n Nosso estúdios parceiros estão em diversas regiões do mundo que entregam uma qualidade de desenvolvimento em VR para todos os tipos de projetos.',

      influenciador: 'Influenciadores, Streamers & Content Creators',
      influenciador_paragrafo: 'Temos uma estrutura de um hub criativo que organizam a sua campanha de acordo com os objetivos da marca com estratégias diferenciadas através de influenciadores, Streamers e ...',
      influenciador_paragrafo_full: 'Temos uma estrutura de um hub criativo que organizam a sua campanha de acordo com os objetivos da marca com estratégias diferenciadas através de influenciadores, Streamers e Criadores de conteúdo espalhados no Brasil, Argentina México USA e Europa.\nUtilizamos uma metodologia para encontrar qual o melhor perfil e qual a estratégia de acordo com uma ferramenta própria - ISC Matrix.\nTambém parceiro de UCG para otimizar e ganhar capilaridade em suas campanhas.',

      tv: 'Digital TV',
      tv_paragrafo: 'Criamos ativações para dar um Boost em suas campanhas através de TVs Digitais. Para as marcas de Game, nosso time de Business Development conecta com desenvolvedores especializados ...',
      tv_paragrafo_full: 'Criamos ativações para dar um Boost em suas campanhas através de TVs Digitais.\nPara as marcas de Game, nosso time de Business Development conecta com desenvolvedores especializados em levar sua gamificação a diversas plataformas de Digital TV para ganhar escala.',

      ooh: 'OOH Gamified',
      ooh_paragrafo: 'Através do OOH Gamified conseguimos alavancar em uma camada superior o engajamento da audiência impactada em uma parada de ônibus, elevador, Metrô  e etc, elevando ...',
      ooh_paragrafo_full: 'Através do OOH Gamified conseguimos alavancar em uma camada superior o engajamento da audiência impactada em uma parada de ônibus, elevador, Metrô  e etc, elevando através da interação um awareness jamais conquistado em OOH. Além de possibilitar campanhas de Loyalty.',

      esport: 'eSports',
      esport_paragrafo: 'Ajudamos conectar as marcas com uma comunidade engajada através de equipes, streamers, organização de torneios, ligas e transmissões em plataformas diversas liderada por influencers ...',
      esport_paragrafo_full: 'Ajudamos conectar as marcas com uma comunidade engajada através de equipes, streamers, organização de torneios, ligas e transmissões em plataformas diversas liderada por influencers/streamers com +1 M de followers.\nNossas equipes garantem entrega mensal de impressões, desta forma as marcas podem adquirir através de CPM.\nTambém organizamos torneios em uma plataforma exclusiva na qual garantimos entre 5 mil a 50 mil jogadores amadores ou profissionais disputando e se divertindo juntos.\n Nossas equipes são construídas de acordo com os objetivos das marcas e encaixa em todos os tipos de budget.',

      licenciamento: 'Licenciamento',
      licenciamento_paragrafo: 'Ajudamos grandes marcas de games a licenciar e poder estar perto dos fans em diversos momentos da sua vida e em diversos seguimentos de gamificação como jogos físicos de ...',
      licenciamento_paragrafo_full: 'Ajudamos grandes marcas de games a licenciar e poder estar perto dos fans em diversos momentos de suas vidas e em diversos segmentos de gamificação, como jogos físicos de tabuleiro, quebra-cabeças, cartas, etc.\nAlém de transformar sua marca de jogos em produtos de consumo massivo.',

      sobre_paragrafo: 'Somos um hub de negócios digitais focado em conectar globalmente marcas com diversos tipos de audiências, através do conceito de customer centricity usamos o entretenimento e soluções tecnológicas para aumentar awareness e a performance.',

      entre_contato: 'Entre em contato para parcerias, tirar dúvidas ou sugestões',
      seu_nome: 'Seu nome',
      input_name: 'José da Silvia',
      input_phone: '(00) 90000-0000',
      phone: 'Telefone',
      escreva_mensagem: 'Escreva sua mensagem aqui',
      enviar_mensagem: 'Enviar mensagem',

      errorValidInputs: 'Preencha os campos do formulario. Eles são obrigatórios para seu contato ser enviado.',
      fale_com_a_gente: 'Fale com a gente',
      desenvolvido: 'Desenvolvido por',

      enviando: 'Enviando...',
      email_invalido: 'E-mail invalido',
      error: 'Ocorreu um erro, tente novamente mais tarde!',
      sucesso: 'Seu e-mail foi enviado com sucesso!'
    }
  }
}

const i18n = createI18n({
  locale: 'en',
  messages
})

export default i18n
